import React, { useState } from "react"
import css from "@styled-system/css"
import { Link } from "gatsby"
import { useAuth } from "contexts/auth"

import {
  Alert,
  Box,
  Button,
  Heading,
  Input,
  RadioGroup,
  SEO,
  Spinner,
  Tabs,
  Text,
  Wrapper,
} from "components"

import ErrorDisplay from "./_errorDisplay"

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

function Login() {
  const { user, login, register } = useAuth()

  const [formState, setFormState] = useState("")
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [repeatPassword, setRepeatPassword] = useState("")
  const [correspondencePreference, setCorrespondencePreference] = useState("")

  // Error Message Hooks
  const [nameError, setNameError] = useState("")
  const [emailError, setEmailError] = useState("")
  const [passwordError, setPasswordError] = useState("")
  const [repeatPasswordError, setRepeatPasswordError] = useState("")
  const [correspondenceError, setCorrespondenceError] = useState("")

  const loginSubmit = e => {
    // Block default HTML5 submit
    e.preventDefault()

    // Run form validation

    let error = false

    // Email Address Check
    setEmailError("")
    if (!/\S+@\S+\.\S+/.test(email)) {
      error = true
      if (email.length === 0) {
        setEmailError("Please enter an email address in this field.")
      } else {
        setEmailError(
          "Invalid email address. Please check your spelling and try again."
        )
      }
    }

    // Password Check
    setPasswordError("")
    if (password.length === 0) {
      error = true
      setPasswordError("Please enter a password in this field.")
    }

    // Submit to server if all checks pass
    if (!error) {
      if (formState !== "processing") {
        setFormState("processing")
        login(email, password, () => {
          setFormState("loginError")
        })
      }
    }
  }

  const registerSubmit = e => {
    // Block default HTML5 submit
    e.preventDefault()

    // Run form validation

    let error = false

    // Name Check
    setNameError("")
    if (name.length === 0) {
      error = true
      setNameError("Please enter your name in this field.")
    }

    // Email Check
    setEmailError("")
    if (!/\S+@\S+\.\S+/.test(email)) {
      error = true
      if (email.length === 0) {
        setEmailError("Please enter an email address in this field.")
      } else {
        setEmailError(
          "Invalid email address. Please check your spelling and try again."
        )
      }
    }

    // Password Check
    setPasswordError("")
    if (password.length < 6) {
      error = true
      if (password.length === 0) {
        setPasswordError("Please enter a password in this field.")
      } else {
        setPasswordError("Password must be six characters or longer.")
      }
    }

    // Repeat Password Check
    setRepeatPasswordError("")
    if (repeatPassword !== password) {
      error = true
      setRepeatPasswordError(
        "Passwords do not match. Please check your spelling and try again."
      )
    }

    // Correspondence Selection Check
    setCorrespondenceError("")
    if (!correspondencePreference) {
      error = true
      setCorrespondenceError("Please select one of the two options.")
    }

    // Submit to server if all checks pass
    if (!error) {
      setFormState("processing")
      if (formState !== "processing") {
        const form = e.target
        fetch("/", {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: encode({
            "form-name": form.getAttribute("name"),
            email,
            name,
            correspondencePreference,
          }),
        })
          .then(() => {
            register(email, password, name, correspondencePreference, () => {
              setFormState("registerError")
            })
          })
          .catch(error => {
            setFormState("registerError")
          })
      }
    }
  }

  return (
    <>
      <SEO
        title="Shareholder Portal | Teine"
        description="Access shareholder documents and other information by logging in."
      />
      <Box
        width={["100%", "calc(100% - 64px)"]}
        mx="auto"
        minHeight="50vh"
        mt={[128, 224]}
        bg="wash"
        position="relative"
      >
        <Box position="relative">
          <Box
            position="absolute"
            top={0}
            left={0}
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="100%"
            height="100%"
            opacity={0}
            css={{
              transition: "opacity 200ms ease-out",
              pointerEvents: "none",
              userSelect: "none",
            }}
            style={
              formState === "processing"
                ? {
                    opacity: 1,
                  }
                : undefined
            }
          >
            <Spinner />
          </Box>
          <Box
            css={{
              transition: "opacity 200ms ease-out",
            }}
            style={
              formState === "processing"
                ? {
                    opacity: 0,
                    pointerEvents: "none",
                    userSelect: "none",
                  }
                : undefined
            }
          >
            <Wrapper py={["layout.7", "layout.8"]} maxWidth={600} mx="auto">
              <Heading
                children="Shareholder Portal"
                mb={["layout.4", "layout.6"]}
                size={800}
                textAlign="center"
              />
              <Tabs
                alignTabs="center"
                tabs={[
                  {
                    label: "Sign in",
                    content: (
                      <Box
                        mt="layout.4"
                        as="form"
                        onSubmit={loginSubmit}
                        noValidate
                      >
                        <Box
                          css={css({
                            "> *": {
                              py: 16,
                            },
                          })}
                        >
                          <Box>
                            {/* Email Entry Field */}
                            <Input
                              id="login-email"
                              type="email"
                              name="sign-in-email"
                              label="Email"
                              required="true"
                              placeholder="Email address"
                              value={email}
                              onChange={e => setEmail(e.target.value)}
                            />
                            {/* Email Error Message */}
                            {emailError && (
                              <ErrorDisplay message={emailError} />
                            )}
                          </Box>
                          <Box>
                            {/* Password Entry Field */}
                            <Box position="relative">
                              <Input
                                id="login-password"
                                type="password"
                                name="sign-in-password"
                                label="Password"
                                required="true"
                                placeholder="Password"
                                value={password}
                                onChange={e => setPassword(e.target.value)}
                              />
                              {/* Forgot Password Link */}
                              <Text
                                position="absolute"
                                top={0}
                                right={0}
                                size={300}
                                color="text"
                              >
                                <Link to="/shareholders/forgot/">
                                  Forgot password
                                </Link>
                              </Text>
                              {/* Password Error Message */}
                              {passwordError && (
                                <ErrorDisplay message={passwordError} />
                              )}
                            </Box>
                          </Box>
                        </Box>
                        <Box mt={["layout.2", "layout.4"]}>
                          <Button children="Submit" type="submit" />
                        </Box>
                        {formState === "loginError" && (
                          <Box mt={"layout.2"}>
                            <Alert
                              heading="Error logging in"
                              text="Check your email and password."
                            />
                          </Box>
                        )}
                      </Box>
                    ),
                  },
                  {
                    label: "Register",
                    content: (
                      <Box
                        mt="layout.4"
                        as="form"
                        onSubmit={registerSubmit}
                        name="shareholderRegistrationForm"
                        data-netlify="true"
                        data-netlify-honeypot="bot-field"
                        noValidate
                      >
                        <input
                          type="hidden"
                          name="form-name"
                          value="shareholderRegistrationForm"
                        />
                        <Text mb="layout.2">
                          If you do not have a login, please fill out the
                          registration form below to create an account and
                          request access to the shareholder portal.
                        </Text>
                        <Box
                          css={css({
                            "> *": {
                              py: 16,
                            },
                          })}
                        >
                          {/* Name Entry Field */}
                          <Box>
                            <Input
                              id="register-name"
                              type="text"
                              name="name"
                              label="Name"
                              required="true"
                              placeholder="Full name"
                              value={name}
                              onChange={e => setName(e.target.value)}
                            />
                            {/* Name Error Message */}
                            {nameError && <ErrorDisplay message={nameError} />}
                          </Box>

                          {/* Email Entry Field */}
                          <Box>
                            <Input
                              id="register-email"
                              type="email"
                              name="email"
                              label="Email"
                              required="true"
                              placeholder="Email address"
                              value={email}
                              onChange={e => setEmail(e.target.value)}
                            />
                            {/* Email Error Message */}
                            {emailError && (
                              <ErrorDisplay message={emailError} />
                            )}
                          </Box>

                          {/* Password Entry Field */}
                          <Box>
                            <Input
                              id="register-password"
                              label="Password"
                              type="password"
                              placeholder="Password"
                              required="true"
                              value={password}
                              onChange={e => setPassword(e.target.value)}
                            />
                            {/* Password Error Message */}
                            {passwordError && (
                              <ErrorDisplay message={passwordError} />
                            )}
                          </Box>

                          <Box>
                            {/* Confirm Password Field */}
                            <Input
                              id="register-password-repeat"
                              label="Repeat Password"
                              type="password"
                              placeholder="Repeat password"
                              required="true"
                              value={repeatPassword}
                              onChange={e => setRepeatPassword(e.target.value)}
                            />
                            {/* Confirm Password Error */}
                            {repeatPasswordError && (
                              <ErrorDisplay message={repeatPasswordError} />
                            )}
                          </Box>

                          {/* Radio Buttons */}
                          <Box>
                            <RadioGroup
                              stack
                              options={[
                                {
                                  label:
                                    "I would like to receive regular corporate correspondence by email. Do not mail the information to me.",
                                  value:
                                    "I would like to receive regular corporate correspondence by email. Do not mail the information to me.",
                                },
                                {
                                  label:
                                    "I want to continue receiving the mailed package.",
                                  value:
                                    "I want to continue receiving the mailed package.",
                                },
                              ]}
                              name="correspondencePreference"
                              value={correspondencePreference}
                              onChange={e => {
                                console.log(e.target.value)
                                setCorrespondencePreference(e.target.value)
                              }}
                            />
                            {/* Radio Error Message */}
                            {correspondenceError && (
                              <ErrorDisplay message={correspondenceError} />
                            )}
                          </Box>
                        </Box>
                        <Box mt={["layout.2", "layout.4"]}>
                          <Button children="Submit" type="submit" />
                        </Box>
                        {formState === "registerError" && (
                          <Box mt={"layout.2"}>
                            <Alert
                              heading="Error registering"
                              text="This email has already been registered."
                            />
                          </Box>
                        )}
                      </Box>
                    ),
                  },
                ]}
              />
            </Wrapper>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default Login
